<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { debounce } from 'vue-debounce'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PaginationCustom from '@/components/table/Pagination.vue'

export default {
   name: 'OssKbli',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
      // ValidationProvider,
      // ValidationObserver,
   },
   data() {
      return {
         loading: true,
         mode: 'INSERT',
         form: {
            kode: null,
            judul: null,
            keterangan: null,
         },
         required,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Kode',
               field: 'kode',
               sortable: false,
               thClass: 'text-center align-middle',
            },

            {
               label: 'Judul',
               field: 'judul',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Keterangan',
               field: 'keterangan',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
      }
   },
   computed: {
      ...mapState({
         myData: state => state.OssKbli.items,
         myProses: state => state.OssKbli.itemsProses,
         myCount: state => state.OssKbli.countAll,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'OssKbli/getData',
         getItem: 'OssKbli/getItem',
         postItem: 'OssKbli/postItem',
         updateItem: 'OssKbli/updateItem',
         deleteItem: 'OssKbli/deleteItem',
      }),
      onAdd() {
         this.resetForm()
         this.$bvModal.show('modal-form')
      },
      onEdit(kode) {
         this.getItem(kode).then(item => {
            this.form = item
            this.mode = 'UPDATE'
            this.$bvModal.show('modal-form')
         })
      },
      onDelete(kode) {
         this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data tidak dapat dipulihkan kembali!',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ya, Hapus!',
            html: false,
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-danger',
               cancelButton: 'btn btn-outline-dark ml-1',
            },
            buttonsStyling: false,
            preConfirm: () => this.deleteItem(kode).then(() => {
               this.$swal('Deleted!', '', 'success')
               this.loadItems()
            }).catch(() => {
               this.$swal('Gagal!', '', 'error')
            }),
         })
      },
      onSave() {
         this.$refs.simpleRules.validate().then(success => {
            console.log(success)
            if (success) {
               if (this.mode === 'INSERT') {
                  this.postItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               } else if (this.mode === 'UPDATE') {
                  this.updateItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               }
            }
         })
      },
      resetForm() {
         this.form = {
            kode: '',
            judul: '',
            keterangan: '',
         }
         this.mode = 'INSERT'
      },
      // VUE GOOD TABLE
      async loadItems() {
         this.loading = true
         await this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data KBLI">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                        @input="onSearch"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button
                        v-b-tooltip.hover.top="'Tambah KBLI'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onAdd()"
                     >
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Refresh Data KBLI'"
                        variant="outline-secondary"
                        size="sm"
                        @click="loadItems()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAdd()">
                           Tambah
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'kode'">
                  <center>
                     <b>{{ props.row.kode }}</b>
                  </center>
               </span>
               <span v-if="props.column.field == 'judul'">
                  <text-highlight :queries="searchTerm">{{ props.row.judul }}</text-highlight>
               </span>
               <span v-if="props.column.field == 'keterangan'">
                  <text-highlight :queries="searchTerm">{{ props.row.keterangan }}</text-highlight>
               </span>

               <span v-if="props.column.field == 'action'">
                  <b-button-group class="mb-1">
                     <b-button
                        v-b-tooltip.hover.top="'Edit'"
                        variant="outline-dark"
                        size="sm"
                        @click="onEdit(props.row.kode)"
                     >
                        <feather-icon icon="EditIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Delete'"
                        variant="outline-danger"
                        size="sm"
                        @click="onDelete(props.row.kode)"
                     >
                        <feather-icon icon="TrashIcon" />
                     </b-button>
                  </b-button-group>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-form"
         scrollable
         size="lg"
         centered
         hide-footer
         no-close-on-backdrop
         title="Form"
      >
         <validation-observer ref="simpleRules">
            <b-form>
               <b-form-group label="Kode" label-for="kode">
                  <validation-provider #default="{ errors }" name="Kode" rules="required">
                     <b-form-input
                        v-model="form.kode"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                     />
                     <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
               </b-form-group>
               <b-form-group label="Judul" label-for="judul">
                  <validation-provider #default="{ errors }" name="Judul" rules="required">
                     <b-form-input
                        v-model="form.judul"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                     />
                     <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
               </b-form-group>
               <b-form-group label="Keterangan" label-for="keterangan">
                  <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                     <b-form-textarea
                        id="keterangan"
                        v-model="form.keterangan"
                        rows="3"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                     />
                     <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
               </b-form-group>
            </b-form>
         </validation-observer>
         <hr>
         <b-button
            v-b-tooltip.hover.top="'Simpan'"
            variant="primary"
            type="button"
            class="float-right"
            @click.prevent="onSave"
         >
            <b-spinner v-if="isLoading" small />Simpan
         </b-button>
      </b-modal>
   </div>
</template>

<style scoped>
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
